<template>
  <v-container class="bg-glass-effect">
    <v-row align="start" style="padding-bottom: 0;">
      <v-col cols="12">
        <BackArrow :route="'miniHomeCovid'"/>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-container style="padding-top: 91.6px; padding-left: 20px; padding-right: 20px; padding-bottom: 20px">
          <img
            :src="image"
            :style="{ width: `${ imageWidth }px`, marginLeft: `-${ imageWidth / 2 - marginCompensation }px`, top: `${ imageTop }%` }"
            class="resultImage"
          >

          <div style="position: relative">
            <v-progress-circular
              class="chart"
              :size="108"
              :width="3"
              :value="(result.rightAwnserAmount * 100) / result.pointAmount"
              :color="color"
              :rotate="-90"
            />
          </div>

          <div style="position: relative">
            <p class="chartNumbers">
              <span :style="{ color }">{{ result.rightAwnserAmount }}</span> /{{ result.pointAmount }}
            </p>
          </div>

          <v-card style="border-radius: 20px; padding: 30px">
            <v-row class="mt-13">
              <v-col cols="12">
                <p style="margin-bottom: 0" class="text-left generic-title">
                  {{ result.resultInterval.description }}
                </p>
              </v-col>

              <v-col v-if="result.rightAwnserAmount > 0" style="padding-bottom: 0" cols="12">
                <p style="margin-bottom: 0" class="text-left generic-text">
                  Você acertou
                </p>
              </v-col>
              <v-col v-else style="padding-bottom: 0" cols="12">
                <p style="margin-bottom: 0" class="text-left generic-text">
                  Infelizmente você não acertou
                </p>
              </v-col>

              <v-col v-if="result.rightAwnserAmount > 0" style="padding-top: 0" cols="12">
                <p style="font-weight: bold; margin-top: 0" class="text-left generic-text">
                  {{ result.rightAwnserAmount }} de {{ result.pointAmount }} perguntas
                </p>
              </v-col>
              <v-col v-else style="padding-top: 0" cols="12">
                <p style="font-weight: bold; margin-top: 0" class="text-left generic-text">
                  nenhuma pergunta
                </p>
              </v-col>

              <v-col cols="12">
                <p class="text-left main-text">
                  <span v-html="result.resultInterval.template"></span>
                </p>
              </v-col>
            </v-row>

<!--            TODO: adicionar ações nos botões (onclick) -->
            <v-row>
              <v-col style="padding-bottom: 0" cols="12">
                <GenericBtn
                  :btn-props="{ block: true }"
                  :on-click="()=>($router.push({name: 'miniHomeCovid'}))"
                >
                  Finalizar
                </GenericBtn>
              </v-col>
              <v-col style="padding-top: 0" cols="12">
                <GenericBtn
                  class="mt-3"
                  color="blue"
                  :btn-props="{
                    block: true,
                    text: true,
                    color: 'secondary',
                    elevation: '2'
                  }"
                  :on-click="()=>($router.go(-1))"
                >Refazer teste
                </GenericBtn>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BackArrow from "../../components/covid/back-arrow/index"
import { quizResultsThemes } from "../../common/constants";
import GenericBtn from "../../components/generic-btn/index"
import { mapMutations } from "vuex"

export default {
  name: 'QuizResult',
  data() {
    return {
      result: {},
      color: '',
      image: '',
      imageWidth: 0,
      imageTop: 0,
      marginCompensation: 0,
    }
  },
  components: {
    BackArrow,
    GenericBtn
  },
  methods: {
    ...mapMutations(['loading']),
    getResult() {
      const userAnswerSurvey = this.$route.params.userAnswerSurvey
      userAnswerSurvey ? this.loadUserAnswer(userAnswerSurvey) : this.loadAlertCase()
    },
    loadUserAnswer(userAnswerSurvey) {
      this.result = {
        pointAmount: userAnswerSurvey.totalQuestionViewed,
        rightAwnserAmount: userAnswerSurvey.rightAnswerAmount,
        resultInterval: {
          description: userAnswerSurvey.resultInterval.description,
          template: userAnswerSurvey.processTemplate,
          theme: userAnswerSurvey.resultInterval.theme,
        }
      }
    },
    loadAlertCase() {
      this.result = {
        pointAmount: 0,
        rightAwnserAmount: 0,
        resultInterval: {
          description: 'Ops, resultado indisponível.',
          template: 'Não foi possível carregar o resultado.',
          theme: quizResultsThemes.covidGood,
        }
      }
    },
    setTheme() {
      let { resultInterval: { theme } } = this.result;
      let {
        covidBad, covidGood, defaultBad, defaultGood
      } = quizResultsThemes;

      let imageCovid = '/img/quiz/img_resultado_quiz_covid.png';
      let imageDefault = '/img/quiz/img_resultado_quiz_default.png'

      let green = '#84DF8D';
      let red = '#FE7B93';

      if(theme === covidBad) {
        this.color = red;
        this.image = imageCovid;
        this.imageWidth = 250;
        this.imageTop = 1;
      }

      if(theme === covidGood) {
        this.color = green;
        this.image = imageCovid;
        this.imageWidth = 250;
        this.imageTop = 1;
      }

      if(theme === defaultGood) {
        this.color = green;
        this.image= imageDefault;
        this.imageWidth = 200;
        this.imageTop = 2.6;
        this.marginCompensation = 3.5;
      }

      if(theme === defaultBad) {
        this.color = red;
        this.image= imageDefault;
        this.imageWidth = 200;
        this.imageTop = 2.6;
        this.marginCompensation = 3.5;
      }
    }
  },
  beforeMount() {
    this.loading(false)
    this.getResult();
    this.setTheme();
  },
  beforeCreate() {
    this.loading(true)
  },
}
</script>

<style scoped>
  .resultImage {
    position: absolute;
    z-index: 5;
    left: 50%;
  }

  .chartNumbers {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 59px;
    text-align: center;
    color: gray;
    width: 100px;
    margin-left: 4px;
    left: 50%;
    top: 10%;
    transform: translate(-50%, -60%);
    position: absolute;
    z-index: 10;
  }

  .chart {
    left: 50%;
    top: 10%;
    transform: translate(-47%, -57%);
    position: absolute;
    z-index: 10;
  }

  .bg-glass-effect {
    background: rgba(255, 255, 255, .6) !important;
    mix-blend-mode: normal !important;
    backdrop-filter: blur(100px) !important;
    height: 100%;
  }
</style>